import React from "react"
import classNames from "classnames"

import { Button } from "../../components/Button"
import { Section, Columns } from "../../components/Containers"
import { Image, Text } from "../../components/Core"

function OurOffice({ heading, locations, colorBack, hasThisSection }) {
  if (!hasThisSection) return <></>

  return (
    <Section colorBack={colorBack} className="color-back--blue our-office">
      <Columns sideColumnsSize={1}>
        <div className="column">
          <Text
            as="h2"
            className="has-text-centered-tablet color-white mt-0"
            text={heading}
          />
          <div className="our-office__locations">
            {locations.map(location => (
              <div
                key={location.button.href}
                className="our-office__location-card">
                <Text as="h4" text={location.heading} />
                <Text as="p" text={location.blurb} />
                <Button
                  buttonText={location.button.buttonText}
                  appearance={location.button.appearance}
                  destination={location.button.destination}
                  href={location.button.href}
                  className={
                    location.button.buttonText.length > 20 ? "large-button" : ""
                  }
                />
              </div>
            ))}
          </div>
        </div>
      </Columns>
    </Section>
  )
}

export default OurOffice
