import React from "react"
import classNames from "classnames"
import { Section, Columns } from "../../components/Containers"
import { Text } from "../../components/Core"

function ReasonsWhy({
  heading,
  list,
  colorBack,
  hasThisSection,
  hasBorderedCards
}) {
  if (!hasThisSection) return <></>

  const cardClasses = classNames("rw__card", {
    bordered: hasBorderedCards
  })

  return (
    <Section colorBack={colorBack}>
      <Columns sideColumnsSize={2}>
        <div className="column">
          <Text
            as="h2"
            className="h3-style has-text-centered mt-0"
            text={heading}
          />
          <div className="rw__list">
            {list.map((card, i) => (
              <div key={i} className={cardClasses}>
                <span className="rw__card-number">{i + 1}</span>
                <div>
                  <Text
                    as="h4"
                    className="rw__card-heading"
                    text={card.heading}
                  />
                  <Text as="p" className="rw__card-blurb" text={card.blurb} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </Columns>
    </Section>
  )
}

export default ReasonsWhy
