import React from "react"

import { Image, Text } from "../../components/Core"
import { Section, Columns } from "../../components/Containers"
import TheaterVideo from "@components/Theater/TheaterVideo"

function TopSection({ post, language }) {
  return (
    <Section zeroBottom>
      <Columns sideColumnsSize={5}>
        <div className="column">
          <Text className="about__blurb" text={post.topBlurb} />
        </div>
      </Columns>

      {post.mainVideo.youtube && (
        <div className="columns mt-3 has-text-centered about-main-theater-image">
          <div className="column is-full-width">
            <TheaterVideo
              buttonClass="contained"
              videoUrl={`https://www.youtube.com/watch?v=${post.mainVideo.youtube}`}
              controls={true}
              playing={true}
              overVideo={true}
              language={language}>
              <Image
                wrapperClassName="show-desktop-only"
                publicId={post.mainVideo.imageId}
                useAR
              />
              <Image
                wrapperClassName="show-desktop-only-inverse"
                publicId={post.mainVideo.imageIdMobile}
                useAR
              />
            </TheaterVideo>
          </div>
        </div>
      )}

      {post.mainVideo.imageId && !post.mainVideo.youtube && (
        <div className="columns mt-3 has-text-centered about-main-theater-image">
          <div className="column is-full-width">
            <Image
              wrapperClassName="show-desktop-only"
              publicId={post.mainVideo.imageId}
              useAR
            />
            <Image
              wrapperClassName="show-desktop-only-inverse"
              publicId={post.mainVideo.imageIdMobile}
              useAR
            />
          </div>
        </div>
      )}
    </Section>
  )
}

export default TopSection
